import React, { useEffect } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Button from "react-bootstrap/Button"
import * as promotionsStyles from "../styles/promotions.module.css"

const Promotions = () => {
  return (
    <Layout>
      <SEO title="Sign up now" />
      <section className={promotionsStyles.section}>
        <h1>Promotions</h1>
        <div>
          <h3>
            Make the most of your summer playing tennis rather than thinking
            about how many hours you will end up playing and how much it will
            be.
          </h3>

          <h3>
            Choose one of our two Summer Unlimited packages running June-Labor
            Day. If you're over 18 years old, play as much as you want from
            Monday to Sunday, 5pm to 6pm for $1,500. If you are thinking about
            your kids, our PeeWee package for 3-6 years old includes unlimited
            play time from Monday to Sunday, 5pm to 6pm for $800.
          </h3>
          <h3>Contact us a reserve your spot.</h3>
        </div>
        <div>
          <form
            method="post"
            action="https://us-central1-moussadrametennis-b44b1.cloudfunctions.net/emailMessage"
          >
            <div className={promotionsStyles.formCol}>
              <div className={promotionsStyles.formGroup}>
                <label>Name*</label>
                <input
                  type="text"
                  placeholder="Enter your Name"
                  name="name"
                  required
                />
              </div>
              <div>
                <label>Surname</label>
                <input
                  type="text"
                  placeholder="Enter your Surname"
                  name="surname"
                />
              </div>
            </div>
            <div className={promotionsStyles.formCol}>
              <div className={promotionsStyles.formGroup}>
                <label>E-mail*</label>
                <input
                  type="email"
                  placeholder="Enter your e-mail"
                  name="email"
                  required
                />
              </div>
              <div>
                <label>Telephone</label>
                <input
                  type="tel"
                  placeholder="Enter your telephone"
                  name="telephone"
                />
              </div>
            </div>
            <div className={promotionsStyles.formTextarea}>
              <label>Message</label>
              <textarea
                aria-setsize="10"
                rows="5"
                placeholder="Message for us"
                name="message"
              />
            </div>
            <Button
              type="submit"
              className={promotionsStyles.button}
              variant="outline-primary"
            >
              Send message
            </Button>
          </form>
        </div>
      </section>
    </Layout>
  )
}

export default Promotions
